import comoVotoDesktop from "./comoVotoDesktop.png";
import comoVotoMobile from "./comoVotoMobile.png";
import formularioOnlineDesktop from "./formularioOnlineDesktop.png";
import formularioOnlineMobile from "./formularioOnlineMobile.png";
import frascoDesktop from "./frascoDesktop.png";
import frascoMobile from "./frascoMobile.png";
import redeemPointsDesktop from "./redeemPointsDesktop.png";
import redeemPointsMobile from "./redeemPointsMobile.png";
import cuantoEstaDesktop from "./CuantoEstaDesktop.png";
import cuantoEstaMobile from "./CuantoEstaMobile.png";

import { media } from "../../Skills/technologies";

export const works = [
  {
    name: "Frasco",
    description: "A page about Jars: types and utilities",
    technologies: media.filter((technology) => {
      return (
        technology.name === "Next.js" ||
        technology.name === "Javascript" ||
        technology.name === "Greensock" ||
        technology.name === "Tailwind.css"
      );
    }),
    imgDesktop: frascoDesktop,
    imgMobile: frascoMobile,
    link: "https://frasco.vercel.app/",
    repo: "https://github.com/RGabrielR/Frasco",
  },
  {
    name: "Redeem Points",
    description: "Page with API integration: gain points and redeem products",
    technologies: media.filter((technology) => {
      return (
        technology.name === "Javascript" ||
        technology.name === "React" ||
        technology.name === "Redux" ||
        technology.name === "Tailwind.css"
      );
    }),
    imgDesktop: redeemPointsDesktop,
    imgMobile: redeemPointsMobile,
    link: "https://redeem-points-2.vercel.app/",
    repo: "https://github.com/RGabrielR/Redeem-points-2",
  },
  {
    name: "Formulario online",
    description: "Digital tool for autocomplete legal documents",
    technologies: media.filter((technology) => {
      return (
        technology.name === "HTML" ||
        technology.name === "CSS" ||
        technology.name === "Javascript"
      );
    }),
    imgDesktop: formularioOnlineDesktop,
    imgMobile: formularioOnlineMobile,
    link: "https://formularioonline.com.ar/",
    repo: false,
  },
  {
    name: "¿Cómo votó?",
    description:
      "Game where you have to guess the vote of some congressmen, the data is fetched using webscrapping",
    technologies: media.filter((technology) => {
      return (
        technology.name === "Javascript" ||
        technology.name === "React" ||
        technology.name === "Cheerio" ||
        technology.name === "Tailwind.css"
      );
    }),
    imgDesktop: comoVotoDesktop,
    imgMobile: comoVotoMobile,
    link: "https://scrapping-games.vercel.app/como-voto",
    repo: "https://github.com/RGabrielR/scrapping-games",
  },
  {
    name: "¿Cuantó está?",
    description:
      "Game where you have to guess the price of apartments in various cities of Argentina. The data is fetched using webscrapping and stored in a Mysql DB",
    technologies: media.filter((technology) => {
      return (
        technology.name === "React" ||
        technology.name === "Puppeteer" ||
        technology.name === "Tailwind.css" ||
        technology.name === "Prisma"
      );
    }),
    imgDesktop: cuantoEstaDesktop,
    imgMobile: cuantoEstaMobile,
    link: "https://scrapping-games.vercel.app/cuanto-esta",
    repo: "https://github.com/RGabrielR/scrapping-games",
  },
];
export const worksByIndex = (index) => works[index % works.length];
