import React,{useEffect} from 'react';
import skillStyles from "./Skills.module.scss";
import { media } from "./technologies";
import Marquee from "react-fast-marquee";
import { useInView } from 'react-intersection-observer';
const Skills = ({onSection}) => {
  const { ref, inView} = useInView({
    threshold: 0.8,
  });
useEffect(() => {
    onSection('Skills')
}, [inView]);

  return (
    <div ref={ref} className={`${skillStyles.display}`} >
      <div className={`${skillStyles.startPoint}`} id='Skills'></div>
      <h1 className={`${skillStyles.title}`}>Skills</h1>

      <Marquee
        gradient={false}
        speed={120}
        pauseOnHover={true}
        pauseOnClick={true}
        delay={0}
        play={true}
        direction="left"
        className={`${skillStyles.carrousel}`}
      
      >
            {media.map((skill, id) => (
                            <div className={`${skillStyles.box}`} key={id} >
                                <img src={skill.src} alt={skill.name} />
                                <h3>
                                    {skill.name}
                                </h3>
                            </div>
                        ))}
          
          </Marquee>
    </div>
  );
};

export default Skills;
