import React,{useState, useEffect} from 'react';
import ContactStyle from './Contact.module.scss';
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useInView } from 'react-intersection-observer';
const Contact = ({onSection}) => {
    const[copied, setCopied] = useState(false);
    const copyNumber = () => {
        setCopied(true);
        navigator.clipboard.writeText('+543814695623');
        setTimeout(() => {setCopied(false)}, 2000);
    }
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0.5,
      });
    useEffect(() => {
        onSection('Contact')
    }, [inView]);
    return ( 
        <>
        <div className={`${ContactStyle.container}`} >
            <div className={`${ContactStyle.startPoint}`} ref={ref} id='Contact'></div>
            <h1 className={`${ContactStyle.title}`}>Contact</h1>
            <p className={`${ContactStyle.description}`}>feel free to contact me for job offers, freelancing, advices, collaborations, etc. This is where you find me:</p>
            <div className={`${ContactStyle.icons}`}>
            <Tippy content='Github'  placement="bottom">
            <a href='https://github.com/RGabrielR' target='_blank'>  
                <img src='/svg/github white.svg'/>
                </a>
                </Tippy>
                <Tippy content='Linkedin'  placement="bottom">
            <a href='https://www.linkedin.com/in/ivan-gabriel-alabi/' target='_blank'>  
                <img src='/svg/linkedin.svg'/>
                </a>
                </Tippy>
                <Tippy content='Mail'  placement="bottom">
            <a href='mailto:ivangabrielalabi@gmail.com' target='_blank'>  
                <img src='/svg/mail.svg'/>
                </a>
                </Tippy>
                <Tippy content='Phone'  placement="bottom"> 
                <img src='/svg/telephone.svg' className={`${ContactStyle.pointer}`}  onClick={() => copyNumber()}/>
                </Tippy>
            </div>
        </div>
        <div className={`${ContactStyle.alert} ${copied ? '' :  `${ContactStyle.hidden}`}`}>
         <h1>📱 + phone number copied to clipboard 📋  </h1>
        </div>
        </>
     );
}
 
export default Contact;