import React, {useEffect} from 'react';
import carrousel from "./Projects.module.scss";
import ProjectDisplay from "./ProjectDisplay";
import { works } from "./media";
import { useInView } from 'react-intersection-observer';
const Projects = ({onSection}) => {
  const SLIDE_COUNT = works.length;
  const slides = Array.from(Array(SLIDE_COUNT).keys());
  const { ref, inView } = useInView({
    threshold: 0.8,
  });
useEffect(() => {
    onSection('Projects')
}, [inView]);
  return (
    <div className={`${carrousel.structure}`}>
      <div className={`${carrousel.startPoint}`} id='Projects' ref={ref}></div>
      <h1 className={`${carrousel.title}`}>Projects</h1>
      <ProjectDisplay slides={slides} />
    </div>
  );
};

export default Projects;
