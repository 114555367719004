import { worksByIndex } from "./media";
import carrousel from "./Projects.module.scss";
import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { PrevButton, NextButton } from "./CarouselButtons";
import "tippy.js/dist/tippy.css";
import Tippy from "@tippyjs/react";
const ProjectDisplay = ({ slides }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    loop: true,
    skipSnaps: false,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on("select", onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <>
      <div className={`${carrousel.embla}`}>
        <div className={`${carrousel.viewport}`} ref={viewportRef}>
          <div className={`${carrousel.container}`}>
            {slides.map((index) => {
              const {
                name,
                description,
                technologies,
                link,
                repo,
                imgDesktop,
                imgMobile,
              } = worksByIndex(index);
              return (
                <div className={`${carrousel.slide}`} key={index}>
                  <div className={`${carrousel.slide__inner}`}>
                    <div className={`${carrousel.slide__info}`}>
                      <h1 className={`${carrousel.slide__title}`}>{name} </h1>
                      <p className={`${carrousel.slide__description}`}>
                        {description}
                      </p>
                      <div className={`${carrousel.slide__technology__and__links}`}>
                        <div className={`${carrousel.slide__technology__box}`}>
                          <h1
                            className={`${carrousel.slide__technology__box__title}`}
                          >
                            Technologies
                          </h1>
                          <div className={`${carrousel.slide__technologies}`}>
                            {technologies.map((technology) => {
                              return (
                                <Tippy
                                  content={`${technology.name}`}
                                  placement="bottom"
                                >
                                  <img
                                    className={`${carrousel.slide__technology}`}
                                    src={technology.src}
                                  />
                                </Tippy>
                              );
                            })}
                          </div>
                        </div>
                        <div className={`${carrousel.slide__links__box}`}>
                          <h1
                            className={`${carrousel.slide__links__box__title}`}
                          >
                            Link
                          </h1>
                          <div className={`${carrousel.slide__links}`}>
                            <Tippy content="Website" placement="top">
                              <a href={`${link}`} target="_blank">
                                <img
                                  className={`${carrousel.slide__link}`}
                                  src="/svg/www.svg"
                                />
                              </a>
                            </Tippy>
                            {repo ? ( <Tippy content="Code" placement="top">
                              <a href={`${repo}`} target="_blank">
                                <img
                                  className={`${carrousel.slide__link}`}
                                  src="/svg/github.svg"
                                />
                              </a>
                            </Tippy>) : ''}
                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        className={`${carrousel.slide__img__desktop}`}
                        src={imgDesktop}
                        alt="Project"
                      />
                      <img
                        className={`${carrousel.slide__img__mobile}`}
                        src={imgMobile}
                        alt="Project"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </>
  );
};

export default ProjectDisplay;
