import React, { useState } from "react";
import navstyles from "./Navbar.module.scss";

const Navbar = ({ section }) => {
  const [navbar, setNavbar] = useState(false);
  const [sticky, setSticky] = useState(false);
  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });
console.log(navbar)
  return (
    <>
    

      <div
        className={`${navstyles.navbar} ${
          sticky ? `${navstyles.sticky}` : ""
        } `}
      >
        <div className={`${navstyles.navbarNotItems}`}>

          <div className={`${navstyles.nameAndLogo}`}>
            <img src="/png/logo.png" className={`${navstyles.logo}    }`} />
            <p className={`${navstyles.name}`}>Gabriel Alabí</p>
          </div>

          <div  onClick={() => setNavbar(!navbar)}>
            <div  className={navbar ?   `${navstyles.hamburguer}` : `${navstyles.hamburguerB}`}>
              <div className={navbar ?   `${navstyles.line1b}` : `${navstyles.line1}`}></div>
              <div className={navbar ?   `${navstyles.line2b}`:`${navstyles.line2}`}></div>
              <div className={navbar ?   `${navstyles.line3b}` : `${navstyles.line3}`}></div>
            </div>
          </div>

        </div>
        <div className={`${navstyles.transition}  ${navbar ? `${navstyles.menuDisplay}` : `${navstyles.notDisplay}` }`}>
          <ul className={`${navstyles.menu}`}>
            <a href="#Home">
              <li
                className={`${
                  section === "Home"
                    ? `${navstyles.selected}`
                    : `${navstyles.menuItems}`
                }`}
              >
                1.HOME
              </li>
            </a>
            <a href="#Skills">
              <li
                className={`${
                  section === "Skills"
                    ? `${navstyles.selected}`
                    : `${navstyles.menuItems}`
                }`}
              >
                2.SKILLS
              </li>
            </a>
            <a href="#Projects">
              <li
                className={`${
                  section === "Projects"
                    ? `${navstyles.selected}`
                    : `${navstyles.menuItems}`
                }`}
              >
                3.PROJECTS
              </li>
            </a>
            <a href="#Contact">
              <li
                className={`${
                  section === "Contact"
                    ? `${navstyles.selected}`
                    : `${navstyles.menuItems}`
                }`}
              >
                4.CONTACT
              </li>
            </a>
          </ul>
          <a href="/CV-Gabriel-Alabi.pdf" download rel="noopener noreferrer" target="_blank">
          <div className={`${navstyles.resume}`}>RESUME</div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
