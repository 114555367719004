import css from "./css-3.svg";
import git from "./git.svg";
import graphql from "./graphql.svg";
import gsap from "./gsap.svg";
import html from "./html.svg";
import javascript from "./javascript.svg";
import mongodb from "./mongodb.svg";
import nextjs from "./next-js.svg";
import react from "./react.svg";
import redux from "./redux.svg";
import sass from "./sass.svg";
import tailwind from "./tailwind.svg";
import nodejs from "./nodejs.svg";
import bootstrap from "./bootstrap.svg";
import cheerio from "./cheerio.svg";
import sql from "./sql.svg";
import prisma from "./prisma.svg";
import puppeteer from "./puppeteer.svg";
export const media = [
  { name: "CSS", src: css },
  { name: "Git", src: git },
  { name: "GraphQL", src: graphql },
  { name: "Greensock", src: gsap },
  { name: "HTML", src: html },
  { name: "Javascript", src: javascript },
  { name: "MongoDB", src: mongodb },
  { name: "Next.js", src: nextjs },
  { name: "React", src: react },
  { name: "Redux", src: redux },
  { name: "Sass", src: sass },
  { name: "Tailwind.css", src: tailwind },
  { name: "Node.js", src: nodejs },
  { name: "Bootstrap", src: bootstrap },
  { name: "Cheerio", src: cheerio },
  { name: "SQL", src: sql },
  { name: "Prisma", src: prisma },
  { name: "Puppeteer", src: puppeteer },
];
