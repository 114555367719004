import React, {useState} from 'react';
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home/Home";
import Skills from "./Components/Skills/Skills";
import Projects from "./Components/Projects/Projects";
import Contact from "./Components/Contact/Contact";

function App() {
  const [section, onSection] = useState('');
  return (
  <>
  <Navbar section={section}/>
  <Home onSection={onSection}/>
  <Skills onSection={onSection}/>
  <Projects onSection={onSection}/>
  <Contact onSection={onSection}/>
  </>
  );
}

export default App;
